html, body {
  min-height: 100%;
  height: 100%;
  margin: 0;
  font-size: small;
}

/* Hide dark mode toggle */
div[aria-label="Dark mode"] {
  display: none !important;
}

.k-scheduler {
  height: auto !important;
  overflow: 'auto' !important;
}

.k-scheduler-footer {
  display: none !important;
}

.k-tooltip {
  background: orange !important;
  color: black;
}

.k-numerictextbox {
  border-bottom-color: transparent !important;
  .k-numeric-wrap {
    border-bottom-color: transparent !important;
  }
  .k-input {
    border-top: 1px solid rgba(0,0,0,0.23) !important;
    border-left: 1px solid rgba(0,0,0,0.23) !important;
    border-bottom: 1px solid rgba(0,0,0,0.23) !important;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 10px;
    min-height: 45px;
    font-size: 14px;
    color: rgb(74, 74, 74);
  }
  .k-select {
    border-top: 1px solid rgba(0,0,0,0.23) !important;
    border-right: 1px solid rgba(0,0,0,0.23) !important;
    border-bottom: 1px solid rgba(0,0,0,0.23) !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 45px;
    width: 30px !important;
    .k-link-increase .k-icon {
      bottom: 0px
    }
    .k-link-decrease .k-icon {
      top: 0px
    }
  }
}

.numerictextbox-large {
  .k-numerictextbox {
    border-bottom-color: transparent !important;
    .k-numeric-wrap {
      border-bottom-color: transparent !important;
    }
    .k-input {
      border-top: 1px solid rgba(0,0,0,0.23) !important;
      border-left: 1px solid rgba(0,0,0,0.23) !important;
      border-bottom: 1px solid rgba(0,0,0,0.23) !important;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding-left: 10px;
      min-height: 55px;
      font-size: 14px;
      color: rgb(74, 74, 74);
    }
    .k-select {
      border-top: 1px solid rgba(0,0,0,0.23) !important;
      border-right: 1px solid rgba(0,0,0,0.23) !important;
      border-bottom: 1px solid rgba(0,0,0,0.23) !important;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      min-height: 55px;
      width: 30px !important;
      .k-link-increase .k-icon {
        bottom: 0px
      }
      .k-link-decrease .k-icon {
        top: 0px
      }
    }
  }
}

.numerictextbox-large-warning {
  .k-numerictextbox {
    border-bottom-color: transparent !important;
    .k-numeric-wrap {
      border-bottom-color: transparent !important;
    }
    .k-input {
      border-top: 1px solid red !important;
      border-left: 1px solid red !important;
      border-bottom: 1px solid red !important;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding-left: 10px;
      min-height: 55px;
      font-size: 14px;
      color: rgb(74, 74, 74);
    }
    .k-select {
      border-top: 1px solid red !important;
      border-right: 1px solid red !important;
      border-bottom: 1px solid red !important;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      min-height: 55px;
      width: 30px !important;
      .k-link-increase .k-icon {
        bottom: 0px
      }
      .k-link-decrease .k-icon {
        top: 0px
      }
    }
  }
}

.k-button-primary, .k-time-accept, .k-button .k-primary {
  background-color: #577790 !important;
  &:hover {
    background-color: #809cb3 !important;
  }
}

.k-time-now {
  color: #577790 !important;
  &:hover {
    color: #809cb3 !important;
  }
}

.k-timepicker {
  border-bottom-color: transparent !important;
  .k-picker-wrap {
    border-bottom-color: transparent !important;
  }
  .k-input {
    border-top: 1px solid rgba(0,0,0,0.23) !important;
    border-left: 1px solid rgba(0,0,0,0.23) !important;
    border-bottom: 1px solid rgba(0,0,0,0.23) !important;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 5px;
    min-height: 52px;
    font-size: 14px;
    color: rgb(74, 74, 74);
  }
  .k-select {
    border-top: 1px solid rgba(0,0,0,0.23) !important;
    border-right: 1px solid rgba(0,0,0,0.23) !important;
    border-bottom: 1px solid rgba(0,0,0,0.23) !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 45px;
    text-align: center;
    width: 30px !important;
    .k-link-increase .k-icon {
      bottom: 0px
    }
    .k-link-decrease .k-icon {
      top: 0px
    }
  }

  .k-icon {
   height: 100%;
  }
}

.k-current-time {
  display: none
}

.disablelegend {
  .highcharts-legend { pointer-events: none !important; }
}
